._content-block {
  font-size: 0.875rem;
}

._content-block strong {
  font-weight: bold;
}

._content-block a {
  color: hsl(var(--primary));
  outline-color: hsl(var(--primary));
  font-weight: 600;
}

._content-block._content-block-post > * {
  margin-bottom: 0.25rem;
}

._content-block._content-block-page > p {
  margin-bottom: 0rem;
  min-height: 24px;
}

/* Basic editor styles */
._content-block {
  :first-child {
    margin-top: 0;
  }

  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  /* Code and preformatted text styles */
  code {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: var(--black);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: "JetBrainsMono", monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--gray-2);
    margin: 2rem 0;
  }

  a {
    color: hsl(var(--primary));
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: hsl(var(--primary));
    }
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
    padding: 0.25rem;
    border-radius: 0.25rem;

    &.ProseMirror-selectednode {
      outline: 3px solid hsl(var(--primary));
    }
  }
}
